<template>
  <div>
    <FilterWrap
      v-show="showFilter"
      v-model="filterValue"
      @confirm="resetList"
    />
    <div v-show="!showFilter" class="potential-customers">
      <HeaderTabs :tabs="tabs" :activeName="activeName" @click="handleTabClick">
        <div
          v-if="activeName === '自有潜在客户'"
          class="navigate-wrap"
          @click="navigateAddPage"
        >
          <span>添加</span>
          <img src="../../assets/images/common/add.png" alt="" />
        </div>
      </HeaderTabs>
      <div class="search-wrap">
        <SearchWrap v-model="searchTotal" @search="resetList"></SearchWrap>
        <div class="sort-wrap">
          <img
            src="../../assets/images/common/sort.png"
            alt=""
            @click="showSortDialog"
          />
          <img
            src="../../assets/images/common/filter.png"
            alt=""
            @click="showFilterDialog"
          />
          <img
            src="../../assets/images/common/navigation.png"
            alt=""
            @click="navigateMapPage"
          />
        </div>
      </div>
      <div class="list-wrap">
        <CustomCard
          class="mb-12"
          v-for="(item, index) in list"
          :key="index"
          :title="item.contactPerson"
          :content="formatCardContent(item)"
          @click="navigateDetailPage(item.id)"
        >
          <div class="action-wrap">
            <div class="label">
              <span class="required">*</span>距我{{ item.distance || "" }}km
            </div>
            <CustomButton size="mini" @click.stop="navigateInfoPage(item.id)"
              >拜访</CustomButton
            >
          </div>
        </CustomCard>
      </div>
      <infinite-loading ref="infinite" @infinite="infiniteHandler">
        <span slot="no-more">
          暂无更多
        </span>
      </infinite-loading>
    </div>
    <SortDialog
      v-model="sortValue"
      ref="sort"
      :sortData="sortData"
      @confirm="resetList"
    />
  </div>
</template>
<script>
import HeaderTabs from "@/components/header/headerTabs";
import SearchWrap from "@/components/common/search";
import CustomCard from "@/components/common/card";
import CustomButton from "@/components/common/button/CustomButton.vue";
import InfiniteLoading from "vue-infinite-loading";
import { potentialCustomersApi } from "@/utils/api.js";
import FilterWrap from "./Filter";
import SortDialog from "@/components/dialog/SortDialog.vue";
export default {
  components: {
    HeaderTabs,
    SearchWrap,
    CustomCard,
    CustomButton,
    InfiniteLoading,
    SortDialog,
    FilterWrap,
  },
  data() {
    return {
      activeName: "史丹利潜在客户",
      tabs: [{ title: "史丹利潜在客户" }, { title: "自有潜在客户" }],
      searchTotal: "",
      list: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
      showFilter: false,
      filterValue: {},
      sortValue: "",
      sortData: [
        {
          label: "距离",
          names: ["ABS(distance)", "ABS(distance) desc"],
        },
        {
          label: "上次拜访时间",
          names: ["(lastVisit)", "(lastVisit) desc"],
        },
      ],
    };
  },
  activated() {
    try {
      this.filterValue =
        JSON.parse(window.localStorage.getItem("shidanli-filter")) || {};
      // window.localStorage.setItem("shidanli-filter", "");
    } catch (err) {
      this.filterValue = {};
    }
    this.resetList();
  },
  methods: {
    handleTabClick(tab) {
      this.activeName = tab;
      this.searchTotal = "";
      this.filterValue = {};
      this.sortValue = "";
      this.resetList();
    },
    // 搜索
    resetList() {
      this.showFilter = false;
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      try {
        await this.search();
      } catch (err) {
        //
      }
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async search(clear) {
      if (clear) {
        this.resetList();
      }
      let response = {};
      if (this.activeName === "史丹利潜在客户") {
        response = await potentialCustomersApi.list({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
          coordinate: "",
          searchTotal: this.searchTotal,
          "page.orderBy": this.sortValue || "ABS(distance)",
          ...this.filterValue,
        });
      } else {
        response = await potentialCustomersApi.distList({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
          coordinate: "",
          searchTotal: this.searchTotal,
          "page.orderBy": this.sortValue || "ABS(distance)",
          ...this.filterValue,
          bussinessManId: this.filterValue.bussinessMan || "",
        });
      }
      if (response.page.total && this.pageNo === 1) {
        this.$toast(`共${response.page.total}条数据`)
      }
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    formatCardContent(item) {
      return [
        {
          label: "详细地址",
          value: `${item.region || ""}${item.town || ""}${item.village || ""}`,
        },
        {
          label: "联系电话",
          value: item.mobile,
          color: "#006EFB",
          type: "tel",
        },
        { label: "上次拜访时间", value: item.lastVisit },
        { label: "拜访次数", value: `${item.visitTimes || 0}次` },
        { label: "属地业务员", value: item.bussinessManName },
      ];
    },
    navigateAddPage() {
      this.$router.push({ name: "potentialCustomersAdd" });
    },
    navigateDetailPage(id) {
      if (this.activeName === "史丹利潜在客户") {
        this.$router.push({
          name: "potentialCustomersShidanliDetail",
          params: { id },
        });
      } else {
        this.$router.push({
          name: "potentialCustomersPersonDetail",
          params: { id },
        });
      }
    },
    navigateInfoPage(id) {
      if (this.activeName === "史丹利潜在客户") {
        this.$router.push({
          name: "potentialCustomersShidanliInfo",
          params: { id },
        });
      } else {
        this.$router.push({
          name: "potentialCustomersPersonInfo",
          params: { id },
        });
      }
    },
    // 顶部操作
    showSortDialog() {
      this.$refs.sort.showDialog();
    },
    showFilterDialog() {
      this.$router.push({ path: "/potentialCustomers/filter" });
    },
    navigateMapPage() {
      this.$router.push({
        path: `/map?type=${this.activeName === "史丹利潜在客户" ? "4" : "5"}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.potential-customers {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .navigate-wrap {
    position: absolute;
    top: 12px;
    right: 24px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
    }
    img {
      height: 14px;
      width: auto;
      margin-left: 4px;
    }
  }
  .search-wrap {
    padding: 10px 24px 10px 12px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sort-wrap {
      flex-shrink: 0;
      img {
        height: 14px;
        width: auto;
        margin-left: 20px;
      }
    }
  }
  .list-wrap {
    padding: 12px;
    .action-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-size: 12px;
        color: #2c2c2c;
        .required {
          color: #cd001f;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
