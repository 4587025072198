<template>
  <div class="potential-customers-basic">
    <Form
      ref="form"
      :rows="
        activeName === 'potentialCustomersShidanliInfo' ? rows : personRows
      "
      v-model="formData"
    />
    <Navigation
      ref="navigation"
      class="mb-12"
      :navigation-item="navigationItem"
    />
    <RowLink :links="links" />
    <FooterButton :loading="loading" @click="submit">保存</FooterButton>
  </div>
</template>
<script>
import Form from "@/components/common/form/index.vue";
import Navigation from "@/components/navigation/index.vue";
import FooterButton from "@/components/common/button/FooterButton.vue";
import { potentialCustomersApi } from "@/utils/api.js";
import RowLink from "@/components/select/rowLink.vue";
export default {
  components: {
    Form,
    Navigation,
    FooterButton,
    RowLink,
  },
  data() {
    return {
      id: "",
      loading: false,
      activeName: "",
      formData: {},
      rows: [
        {
          key: "potentialType",
          label: "潜在客户类型",
          type: "picker",
          gap: true,
          dictType: "potential_type",
          defaultLabel: "potentialTypeName",
        },
        {
          key: "contactPerson",
          label: "联系人",
          type: "input",
          required: true,
        },
        {
          key: "mobile",
          label: "手机",
          type: "input",
          inputType: "tel",
          required: true,
        },
        // 所属业务主任不能修改，不需要传
        // { key: "业务主任", label: "业务主任", type: "picker", required: true },
        {
          key: "bussinessManId",
          label: "属地业务员",
          type: "picker",
          required: true,
          dictType: "salesman",
          defaultLabel: "bussinessManName",
        },
        {
          key: "dealerId",
          label: "所属经销商",
          type: "picker",
          required: true,
          dictType: "dealer",
          defaultLabel: "dealerName",
        },
        {
          key: "regionId",
          label: "所属市县",
          type: "picker",
          required: true,
          dictType: "region",
          defaultLabel: "region",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.towninfoId[0].fetchTown(
              this.formData.regionId
            );
            this.$refs.form.$refs.towninfoId[0].reset();
            this.$refs.form.$refs.villageId[0].reset();
            this.$set(this.formData, "town", "");
            this.$set(this.formData, "village", "");
          },
        },
        {
          key: "towninfoId",
          label: "所属乡镇",
          type: "picker",
          required: true,
          dictType: "town",
          defaultLabel: "town",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.villageId[0].fetchVillage(
              this.formData.towninfoId
            );
            this.$refs.form.$refs.villageId[0].reset();
          },
        },
        {
          key: "villageId",
          label: "所属村",
          type: "picker",
          required: true,
          gap: true,
          dictType: "village",
          defaultLabel: "village",
          valueString: "id",
          labelString: "name",
        },
        // 村级覆盖是将所属村级传入判断的，，所属村级字段villageId
        // { key: "村级覆盖", label: "村级覆盖", type: "picker", required: true },
        {
          key: "plantCover",
          label: "作物覆盖",
          type: "picker",
          required: true,
          dictType: "plantSelect",
          defaultLabel: "plantCover",
        },
        {
          key: "runCompBrand",
          label: "经营竞品品牌",
          type: "input",
          required: true,
          // dictType: "crm_compete_brand",
          dictType: "brand",
          defaultLabel: "runCompBrandValue",
        },
        {
          key: "otherCompBrand",
          label: "其他竞品品牌",
          type: "input",
          required: true,
          gap: true,
        },
        {
          key: "totalCapacity",
          label: "去年总销量",
          type: "input",
          inputType: "number",
          required: true,
          unit: "吨",
          gap: true,
        },
      ],
      personRows: [
        {
          key: "contactPerson",
          label: "联系人",
          required: true,
          type: "input",
        },
        {
          key: "mobile",
          label: "手机",
          required: true,
          type: "input",
          inputType: "tel",
        },
        {
          key: "birthday",
          label: "生日",
          required: true,
          gap: true,
          type: "date",
        },
        {
          key: "dealerId",
          label: "所属经销商",
          type: "picker",
          dictType: "dealer",
          defaultLabel: "dealerName",
        },
        {
          key: "regionId",
          label: "所属市县",
          required: true,
          type: "picker",
          dictType: "region",
          defaultLabel: "region",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.towninfoId[0].fetchTown(
              this.formData.regionId
            );
            this.$refs.form.$refs.towninfoId[0].reset();
            this.$refs.form.$refs.villageId[0].reset();
            this.$set(this.formData, "town", "");
            this.$set(this.formData, "village", "");
          },
        },
        {
          key: "towninfoId",
          label: "所属乡镇",
          required: true,
          type: "picker",
          dictType: "town",
          defaultLabel: "town",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.villageId[0].fetchVillage(
              this.formData.towninfoId
            );
            this.$refs.form.$refs.villageId[0].reset();
          },
        },
        {
          key: "villageId",
          label: "所属村",
          type: "picker",
          gap: true,
          dictType: "village",
          defaultLabel: "village",
          valueString: "id",
          labelString: "name",
        },
        {
          key: "bussinessManId",
          label: "属地业务员",
          type: "picker",
          dictType: "salesman",
          defaultLabel: "bussinessManName",
          gap: true,
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    navigationItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    links() {
      if (this.activeName === "potentialCustomersShidanliInfo") {
        return [
          {
            label: "近期拜访记录",
            path: `/visit/list?type=potentialCustomers&id=${this.id}&tab=shidanli`,
          },
        ];
      } else {
        return [
          {
            label: "近期拜访记录",
            path: `/visit/list?type=potentialCustomers&id=${this.id}&tab=ziyou`,
          },
        ];
      }
    },
  },
  watch: {
    detail() {
      this.formData = {
        ...this.formData,
        ...this.detail,
        birthday: new Date(this.detail.birthday),
        bussinessManId: this.detail.bussinessMan,
      };
      if (this.formData.regionId) {
        this.$refs.form.$refs.towninfoId[0].fetchTown(this.formData.regionId);
      }
      if (this.formData.towninfoId) {
        this.$refs.form.$refs.villageId[0].fetchVillage(
          this.formData.towninfoId
        );
      }
    },
  },
  activated() {
    this.activeName = this.$route.name;
    this.id = this.$route.params.id;
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const {
        potentialType,
        villageId,
        mobile,
        contactPerson,
        otherCompBrand,
        towninfoId,
        regionId,
        bussinessManId,
        runCompBrand,
        totalCapacity,
        plantCover,
        dealerId,
        birthday,
      } = this.formData;
      try {
        if (this.activeName === "potentialCustomersShidanliInfo") {
          await potentialCustomersApi.save({
            id: this.id,
            userId: "",
            plantCover: plantCover || "",
            bussinessManId: bussinessManId || "",
            bussinessMan: bussinessManId || "",
            potentialType: potentialType || "1",
            villageId: villageId || "",
            mobile: mobile || "",
            contactPerson: contactPerson || "",
            otherCompBrand: otherCompBrand || "",
            towninfoId: towninfoId || "",
            regionId: regionId || "",
            runCompBrand: runCompBrand || "",
            totalCapacity: totalCapacity || "",
            dealerId: dealerId || "",
            location: this.$refs.navigation.value,
            coordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
          });
        } else {
          await potentialCustomersApi.distSave({
            id: this.id,
            userId: "",
            plantCover: plantCover || "",
            bussinessManId: bussinessManId || "",
            bussinessMan: bussinessManId || "",
            villageId: villageId || "",
            contactPerson: contactPerson || "",
            mobile: mobile || "",
            otherCompBrand: otherCompBrand || "",
            towninfoId: towninfoId || "",
            regionId: regionId || "",
            runCompBrand: runCompBrand || "",
            totalCapacity: totalCapacity || "",
            dealerId: dealerId || "",
            potentialType: "1",
            birthday,
            location: this.$refs.navigation.value,
            coordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
          });
        }
        this.$toast("保存成功");
        // this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
