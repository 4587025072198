<template>
  <div class="potential-customers-add">
    <HeaderNavigation class="mb-12" type="book"></HeaderNavigation>
    <Form ref="form" :rows="rows" v-model="detail"> </Form>
    <Navigation ref="navigation" :show-navigation="false" />
    <FooterButton :loading="loading" @click="submit">保存</FooterButton>
  </div>
</template>
<script>
import HeaderNavigation from "@/components/header/headerWithNavigation";
import FooterButton from "@/components/common/button/FooterButton.vue";
import Form from "@/components/common/form/index.vue";
import Navigation from "@/components/navigation/index.vue";
import { potentialCustomersApi } from "@/utils/api.js";
import { getDealerId, getDealerName } from "@/utils/user.js";
export default {
  components: {
    HeaderNavigation,
    FooterButton,
    Form,
    Navigation,
  },
  data() {
    return {
      loading: false,
      detail: { dealerName: getDealerName() },
      rows: [
        {
          key: "contactPerson",
          label: "联系人",
          required: true,
          type: "input",
        },
        {
          key: "mobile",
          label: "手机",
          required: true,
          type: "input",
          inputType: "tel",
        },
        {
          key: "birthday",
          label: "生日",
          required: true,
          type: "date",
          gap: true,
        },
        {
          key: "dealerName",
          label: "所属经销商",
          required: true,
        },
        {
          key: "regionId",
          label: "所属市县",
          required: true,
          type: "picker",
          dictType: "region",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.towninfoId[0].fetchTown(this.detail.regionId);
            this.$refs.form.$refs.towninfoId[0].reset();
            this.$refs.form.$refs.villageId[0].reset();
          },
        },
        {
          key: "towninfoId",
          label: "所属乡镇",
          required: true,
          type: "picker",
          dictType: "town",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.villageId[0].fetchVillage(
              this.detail.towninfoId
            );
            this.$refs.form.$refs.villageId[0].reset();
          },
          beforeOpenCheck: () => {
            if (!this.detail.regionId) {
              return "请选择市县";
            }
          },
        },
        {
          key: "villageId",
          label: "所属村",
          required: true,
          type: "picker",
          gap: true,
          dictType: "village",
          valueString: "id",
          labelString: "name",
          beforeOpenCheck: () => {
            if (!this.detail.regionId) {
              return "请选择市县";
            }
            if (!this.detail.towninfoId) {
              return "请选择乡镇";
            }
          },
        },
        {
          key: "plantCover",
          label: "作物覆盖",
          required: true,
          type: "picker",
          gap: true,
          dictType: "plantSelect",
        },
        {
          key: "runCompBrand",
          label: "经营竞品品牌",
          required: true,
          type: "picker",
          // dictType: "crm_compete_brand",
          dictType: "brand",
        },
        {
          key: "otherCompBrand",
          label: "其他竞品品牌",
          type: "input",
          gap: true,
        },
        {
          key: "totalCapacity",
          label: "去年复合肥总容量",
          required: true,
          type: "input",
          inputType: "number",
          unit: "吨",
        },
        {
          key: "bussinessManId",
          label: "属地业务员",
          required: true,
          type: "picker",
          gap: true,
          dictType: "salesman",
        },
      ],
    };
  },
  activated() {
    this.detail = { dealerName: getDealerName() };
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const {
        villageId,
        contactPerson,
        mobile,
        otherCompBrand,
        towninfoId,
        regionId,
        runCompBrand,
        totalCapacity,
        plantCover,
        bussinessManId,
      } = this.detail;
      try {
        await potentialCustomersApi.distSave({
          plantCover: plantCover || "",
          bussinessMan: bussinessManId || "",
          bussinessManId: bussinessManId || "",
          villageId: villageId || "",
          contactPerson: contactPerson || "",
          mobile: mobile || "",
          otherCompBrand: otherCompBrand || "",
          towninfoId: towninfoId || "",
          regionId: regionId || "",
          runCompBrand: runCompBrand || runCompBrand,
          totalCapacity: totalCapacity || "",
          dealerId: getDealerId(),
          location: this.$refs.navigation.value,
          coordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
          potentialType: "1",
        });
        this.$toast("新增成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.potential-customers-add {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  padding-bottom: 56px;
}
</style>
