<template>
  <div class="filter-page">
    <Header class="mb-12" />
    <Form ref="form" :rows="rows" v-model="detail" />
    <FooterButton
      v-show="hideshow"
      cancelLabel="重置"
      confirmLabel="确认"
      @cancel="reset"
      @confirm="confirm"
    />
  </div>
</template>
<script>
import Header from "@/components/header";
import FooterButton from "@/components/common/button/FooterDoubleButton.vue";
import Form from "@/components/common/form/index.vue";
export default {
  components: {
    FooterButton,
    Header,
    Form,
  },
  data() {
    return {
      hideshow: true,
      docmHeight: document.documentElement.clientHeight,  //默认屏幕高度
      showHeight: document.documentElement.clientHeight,   //实时屏幕高度

      detail: {},
      rows: [
        {
          key: "searchCity",
          label: "区县",
          type: "picker",
          dictType: "region",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.towninfoId[0].fetchTown(
              this.detail.searchCity
            );
            this.$refs.form.$refs.towninfoId[0].reset();
          },
        },
        {
          key: "towninfoId",
          label: "乡镇",
          type: "picker",
          dictType: "town",
          valueString: "id",
          labelString: "name",
        },
        {
          key: "runCompBrand",
          label: "竞品品牌",
          type: "picker",
          dictType: "crm_compete_brand",
        },
        {
          key: "bussinessMan",
          label: "属地化人员",
          type: "picker",
          gap: true,
          dictType: "salesman",
        },
        { key: "capacity", label: "容量", type: "range", unit: "吨" },
        { key: "distance", label: "距离", type: "range", unit: "km" },
      ],
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  mounted() {
    // window.onresize监听页面高度的变化
    window.onresize = ()=>{
      return(()=>{
        this.showHeight = document.body.clientHeight;
      })()
    }
  },
  //监听
  watch:{
    showHeight:function() {
      if(this.docmHeight > this.showHeight){
        this.hideshow=false
      }else{
        this.hideshow=true
      }
    }
  },
  activated() {
    if (!window.localStorage.getItem("shidanli-filter")) {
      this.detail = {};
    }
  },
  methods: {
    reset() {
      this.detail = {};
      this.$emit("input", {});
    },
    confirm() {
      const {
        searchCity,
        towninfoId,
        bussinessMan,
        runCompBrand,
        capacity,
        distance,
      } = this.detail;
      const query = {
        searchCity,
        towninfoId,
        bussinessMan,
        runCompBrand,
        leftCapacity: capacity ? capacity[0] : "",
        rightCapacity: capacity ? capacity[1] : "",
        distance1: distance ? distance[0] : "",
        distance2: distance ? distance[1] : "",
      };
      // this.$emit("input", query);
      // this.$emit("confirm");
      window.localStorage.setItem(
        "shidanli-filter",
        JSON.stringify(query || {})
      );
      this.$router.push({ path: "/potentialCustomers" });
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-page {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
}
</style>
